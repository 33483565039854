<template>
<div v-if="getAllowFullSite" class="main content-page contact">

    <div class="vertical-image"></div>

    <div v-if="loading" class="content-page-inner contact-content contact-content-loading">
        <h1>Connect With The Great Awakening Project</h1>
        <div class="loading">
            <font-awesome-icon icon="spinner" class="icon fa-spin loading-icon"/>
            <p v-html="loadingMessage"></p>
        </div>
    </div>

    <div v-else class="content-page-inner contact-content">
        <h1>Connect With The Great Awakening Project</h1>
        <div v-if="contactSent" class="contact-sent">
            <p>Your contact request was sent!</p>
        </div>
        <form v-else class="contact-form">
            <label for="name">Your Name</label>
            <input type="text" id="name" name="name" v-model="contact.full_name">
            <div class="two-columns">
                <div>
                    <label for="email">Email Address</label>
                    <input type="text" id="email" name="email" v-model="contact.email_address">
                </div>
                <div>
                    <label for="phone">Phone Number (optional)</label>
                    <input type="tel" id="phone" name="phone" v-model="contact.phone_number">
                </div>
            </div>
            <div class="two-columns">
                <div>
                    <label for="interest">Which Sphere are you most interested in?</label>
                    <select v-model="contact.sphere_id">
                        <option value="">Select...</option>
                        <option v-for="(sphere, index) in spheres" :value="sphere.sphere_id">{{ sphere.description }}</option>
                    </select>
                </div>
                <div>
                    <label for="how_heard">How did you hear about GAP?</label>
                    <select v-model="contact.how_heard">
                        <option value="">Select...</option>
                        <option v-for="(howHeard, index) in howHeardOptions" :value="howHeard">{{ howHeard }}</option>
                    </select>
                </div>
            </div>

            <div class="two-columns">
                <div>
                    <label for="postal_code">Zip Code (optional*)</label>
                    <input type="text" id="postal_code" name="postal_code" v-model="contact.postal_code">
                </div>
                <div v-if="contact.how_heard.indexOf('Other') > -1">
                    <label for="how_heard_other">Describe the 'other' way you heard about gap</label>
                    <input type="text" id="how_heard_other" name="how_heard_other" v-model="contact.how_heard_other"></textarea>
                </div>
            </div>

            <label for="notes">Any comments or questions?</label>
            <textarea id="notes" name="notes" v-model="contact.note"></textarea>

            <div class="two-columns controls">
                <button :class="['button-dark', { disabled: sending }]" @click.prevent="sendContact()"><font-awesome-icon v-if="sending" icon="spinner" class="fa-spin" /><span v-else>Send Contact</span></button>
                <div class="message">{{ message }}</div>
            </div>

            <div class="note">
                <p>* Your Zip Code is optional but providing it will help us connect you with news and events in your area.</p>
                <p>We promise to keep all of your information safe and private! See our complete <router-link to="/privacy-policies">privacy policies</router-link> here.</p>
            </div>

        </form>

        <div class="two-columns">
            <div class="contact-info">
                <label>Mailing Address</label>
                <div>
                    {{ mailingAddress.address_1 }} &bull; <span v-if="mailingAddress.address_2"> {{ mailingAddress.address_2 }} &bull; </span>{{ mailingAddress.city }}, {{ mailingAddress.state }} {{ mailingAddress.postal_code }}
                </div>
            </div>
            <div class="contact-info">
                <label>Email Address</label>
                <div><a href="mailto:info@gapmovement.com">info@gapmovement.com</a></div>
            </div>
        </div>

    </div>

</div>
</template>

<script>
import StateSelect from '@/components/StateSelect.vue'
export default {
    name: 'contact',
    components: {
        'state-select': StateSelect
    },
    data () {
        return {
            loading: true,
            loadingMessage: '',
            message: '',
            mailingAddress: {},
            emailToAddress: '',
            contact: {
                email_address: '',
                phone_number: '',
                full_name: '',
                how_heard: '',
                sphere_id: '',
                postal_code: '',
                note: '',
                form_type: 'WEBSITE_CONTACT_FORM'
            },
            howHeardOptions: [],
            spheres: [],
            sending: false,
            contactSent: false
        }
    },
    created() {
        if (!this.getAllowFullSite) {
            this.$router.push('/coming-soon')
        }
        window.scrollTo(0, 0);
        setTimeout(() => {this.loadingMessage = "Your browser can't seem to connect to our server.<br>Try reloading this page."}, 3000)
        this.apiCall({ destination: 'get_contact_form_options', data: { context: 'contact' } }).then(obj => {
            this.loading = false
            this.loadingMessage = ''
            if (obj.status == 'success') {
                this.mailingAddress = obj.mailing_address
                this.emailToAddress = obj.email_address
                this.howHeardOptions = obj.how_heard
                this.spheres = obj.spheres
                if (this.$route.query.interest) {
                    this.spheres.forEach(sphere => {
                        if (sphere.sphere_code == this.$route.query.interest.toUpperCase()) {
                            this.contact.sphere_id = sphere.sphere_id
                        }
                    })
                }
            } else {
                this.message = obj.message
            }
        })
    },
    methods: {
		updateSelectedState(state) {
			this.donation.state = state
		},
        sendContact() {
            if (this.sending) {
                return false
            }
            this.message = ''
            let validateMessage = ''
            if (!validateMessage && !this.contact.full_name) {
                validateMessage = 'Please enter your name.'
            }
            if (!validateMessage && !this.isValidEmailAddress(this.contact.email_address)) {
                validateMessage = 'Please enter a full email address.'
            }
            if (!validateMessage && this.contact.phone_number && !this.isValidPhoneNumber(this.contact.phone_number)) {
                validateMessage = 'Please enter a complete phone number.'
            }
            if (validateMessage) {
                this.message = validateMessage
                return false
            }
            this.message = 'Sending your contact...'
            this.sending = true
            this.apiCall({ destination: 'save_frontend_form', data: this.contact }).then(obj => {
                if (obj.status == 'success') {
                    this.contactSent = true
                } else {
                    this.message = obj.message
                    this.sending = false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.vertical-image {
    overflow: hidden;
    background-image: url("~@/assets/contact2.jpg");
    background-size: cover;
    background-position: center bottom;
}
.contact-content {
    padding: 1em 4em 1em 4em;
    text-align: left;
}
.contact-content-loading {
    height: 42em;
    .loading {
        text-align: center;
        padding: 3em 0;
    }
    .loading-icon {
        font-size: 2em;
        margin: 1em;
        color: #666;
    }
}
.controls {
    margin: 1em 0;
    align-items: center;
}
.contact-sent {
    height: 23em;
    p { font-size: 1.25em; margin: 1em 0; }
}
.contact-info {
    border-top: 1px solid #ccc;
    margin: 1em 0 0 0;
    //font-size: 1em;
}
form {
    margin-bottom: 3em;
}
</style>
